<template>
  <fragment>
    <Header
      :title="content.title"
      :omschrijving="content.korte_omschrijving"
      :headerafbeelding="content.header_afbeelding.permalink"
    />

    <section class="contact padding-y-xl">
      <div class="container max-width-lg">
        <div class="grid gap-xl">
          <div class="col-5@md">
            <dl class="details-list ">
              <div class="details-list__item">
                <dt class="details-list__dt">Contactgegevens</dt>
                <dd class="details-list__dd">
                  Klaashuisstraat 17 <br />
                  7481 EN Haaksbergen
                </dd>
              </div>
              <div class="details-list__item">
                <dt class="details-list__dt">E-mail</dt>
                <dd class="details-list__dd">
                  <a href="mailto:info@oostelijkcardiologengenootschap.nl"
                    >info@oostelijkcardiologengenootschap.nl</a
                  >
                </dd>
              </div>
              <div class="details-list__item">
                <dt class="details-list__dt">KVK</dt>
                <dd class="details-list__dd">08111760</dd>
              </div>
            </dl>
          </div>
          <div class="col-7@md">
            <form  name="contact" data-netlify="true">
              <div class="margin-bottom-sm">
                <label class="form-label margin-bottom-xxs" for="naam"
                  >Naam</label
                >
                <input
                  class="form-control width-100%"
                  type="text"
                  name="naam"
                  id="naam"
                  required=""
                />
              </div>
              <div class="margin-bottom-sm">
                <label class="form-label margin-bottom-xxs" for="email"
                  >E-mail adres</label
                >
                <input
                  class="form-control width-100%"
                  type="email"
                  name="email"
                  id="email"
                />
              </div>
              <div class="margin-bottom-sm">
                <label class="form-label margin-bottom-xxs" for="vraag_over"
                  >Uw bericht</label
                >
                <textarea
                  class="form-control width-100%"
                  rows="9"
                  name="vraag_over"
                  id="vraag_over"
                ></textarea>
              </div>
              <div class="text-right">
                <button class="btn btn--primary">Versturen</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </fragment>
</template>

<script>
import { Fragment } from "vue-fragment";

import Header from "@/components/Header";

export default {
  name: "Contact",
  components: {
    Fragment,
    Header,
  },
  mounted() {},
  computed: {
    content() {
      // console.log('r', this.$route.path);
      // console.log('con', this.$store.state.siteContent)
      const pageContent = this.$store.state.siteContent.filter((page) => {
        return page.uri == this.$route.path;
      });
      console.log(pageContent);
      return pageContent[0];
    },
  },
};
</script>
