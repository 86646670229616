<template>
  <section>
    <div class="container max-width-md">
      <div class="grid gap-sm">
        <div class="col-12">
          <div class="invoice-btn-wrapper flex flex-center margin-bottom-md">
            <button
              onClick="event.preventDefault();window.print();"
              class="btn btn--subtle"
            >
              <svg
                class="icon icon--xs margin-right-xxs"
                viewBox="0 0 16 16"
                aria-hidden="true"
              >
                <g fill="currentColor">
                  <rect x="3" width="10" height="3"></rect>
                  <path
                    d="M15,5H1C0.4,5,0,5.4,0,6v6c0,0.6,0.4,1,1,1h2v2c0,0.6,0.4,1,1,1h8c0.6,0,1-0.4,1-1v-2h2c0.6,0,1-0.4,1-1V6 C16,5.4,15.6,5,15,5z M11,14H5V9h6V14z"
                  ></path>
                </g>
              </svg>

              <span>Print</span>
            </button>
          </div>

          <div class="invoice">
            <header class="invoice__section invoice__section--bg">
              <ul class="grid">
                <li class="col-6">
                  <img
                    width="120"
                    src="https://ocg.strakk.digital/assets/logo.png"
                    alt="Logo"
                  />
                  <h3> Oostelijk Cardiologen Genootschap</h3>
                </li>

                <li class="col-6">
                  <ul class="grid gap-y-lg">
                    <li class="col-6 padding-right-sm">
                      <p class="invoice__label">Factuur nummer</p>

                      <div
                        class="text-component text-sm line-height-xs text-space-y-xs color-contrast-medium"
                      >
                        <p>{{ $route.params.id }}</p>
                      </div>
                    </li>

                    <li class="col-6 padding-right-sm">
                      <p class="invoice__label">Datum</p>

                      <div
                        class="text-component text-sm line-height-xs text-space-y-xs color-contrast-medium"
                      >
                      
                        <p>{{ formatDate(payment.paid) }}</p>
                      </div>
                    </li>

                    <li class="col-6 padding-right-sm">
                      <p class="invoice__label">Van</p>

                      <div
                        class="text-component text-sm line-height-xs text-space-y-xs color-contrast-medium"
                      >
                        <p>Oostelijk Cardiologen Genootschap</p>
                        <p>Klaashuisstraat 17</p>
                        <p>7481 EN Haaksbergen</p>
                        <p>KVK: 08111760</p>
                      </div>
                    </li>

                    <li class="col-6 padding-right-sm">
                      <p class="invoice__label">Aan</p>

                      <div
                        class="text-component text-sm line-height-xs text-space-y-xs color-contrast-medium"
                      >
                        <p>{{ $store.state.userProfile.naam }}</p>
                        <p>{{ $store.state.userProfile.organisatie }}</p>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </header>

            <div class="invoice__section">
              <table class="invoice__table" aria-label="Items list table">
                <thead
                  class="border-bottom border-contrast-lower border-opacity-50%"
                >
                  <tr>
                    <th
                      class="invoice__table-cell font-semibold color-contrast-higher width-50%"
                      scope="col"
                    >
                      Omschrijving
                    </th>
                    <th
                      class="invoice__table-cell font-semibold color-contrast-higher width-25%"
                      scope="col"
                    >
                      Hoeveelheid
                    </th>
                    <th
                      class="invoice__table-cell font-semibold color-contrast-higher width-25%"
                      scope="col"
                    >
                      Prijs
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td
                      class="invoice__table-cell color-contrast-medium"
                      role="cell"
                    >
                     {{ payment.description }}
                    </td>

                    <td
                      class="invoice__table-cell color-contrast-medium"
                      role="cell"
                    >
                      1
                    </td>

                    <td
                      class="invoice__table-cell color-contrast-medium"
                      role="cell"
                    >
                      €{{ payment.amount.value }}
                    </td>
                  </tr>

                </tbody>
              </table>

              <div
                class="border-top border-contrast-lower border-opacity-50% margin-bottom-xl"
              >
                <div class="grid">
                  <div class="col-6 offset-6">
                    <table
                      class="invoice__table"
                      aria-label="Order summary table"
                    >
                      <tbody>
                        <tr>
                          <th
                            class="invoice__table-cell width-50% color-contrast-medium"
                          >
                            Subtotaal
                          </th>
                          <td
                            class="invoice__table-cell width-50% color-contrast-medium"
                          >
                            €{{ payment.amount.value }}
                          </td>
                        </tr>

                        <tr>
                          <th class="invoice__table-cell color-contrast-medium">
                            BTW 21%
                          </th>
                          <td class="invoice__table-cell color-contrast-medium">
                             €0,-
                          </td>
                        </tr>

    

                        <tr>
                          <th
                            class="invoice__table-cell font-semibold color-contrast-higher"
                          >
                            Totaal
                          </th>
                          <td
                            class="invoice__table-cell font-semibold color-contrast-higher"
                          >
                             €{{ payment.amount.value }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- 
              <div class="grid">
                <div class="col-6">
                  <p class="invoice__label">Payment Method</p>

                  <div
                    class="text-component text-sm line-height-xs text-space-y-xs color-contrast-medium"
                  >
                    <p>Bank Name Here</p>
                    <p>IBAN: 0000000000000000</p>
                    <p>SWIFT: XXXXX</p>
                  </div>
                </div>

                <div class="col-6">
                  <p class="invoice__label">Notes</p>

                  <div
                    class="text-component text-sm line-height-xs text-space-y-xs color-contrast-medium"
                  >
                    <p>Add a note...</p>
                  </div>
                </div>
              </div> -->
            </div>

            <footer class="invoice__section invoice__section--bg">
              <div class="grid">
                <div class="col-6">
                  <p class="invoice__paid-stamp">
                    <svg
                      class="icon icon--xxs margin-right-xxxs"
                      viewBox="0 0 12 12"
                      aria-hidden="true"
                    >
                      <g fill="currentColor">
                        <path
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M1 6.5l3 3 7-7"
                        ></path>
                      </g>
                    </svg>
                    <span>Betaald</span>
                  </p>
                </div>

                <div class="col-3 offset-2">
                  <p class="invoice__label">Vragen?</p>

                  <div
                    class="text-component text-sm line-height-xs text-space-y-xs"
                  >
                    <p>
                      <a href="mailto:info@oostelijkcardiologengenootschap.nl"
                        >info@oostelijkcardiologengenootschap.nl</a
                      >
                    </p>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  layout: "invoice",
  mounted() {
    this.getCustomerPayments();
  },
  data() {
    return {
      payments: [],
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).utc().format("LLLL");
    },
    getCustomerPayments() {
      axios
        .get(`/api/customerPayments?cid=${this.$store.state.userProfile.cid}`)
        .then((res) => {
          if (res.status == 200) {
            this.payments = res.data.payments.filter(
              (payment) => payment.status == "paid"
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  computed: {
    payment() {
      return this.payments.find(
        (payment) => payment.id == this.$route.params.id
      );
    },
  },
};
</script>
