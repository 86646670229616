<template>
  <fragment>
    <Header
      :title="content.title"
      :omschrijving="content.introductietekst"
      :headerafbeelding="content.header_afbeelding.permalink"
    />
    <section class="team padding-y-xl">
      <div class="container max-width-adaptive-md">
        <div class="grid gap-sm">
          <div
            class="col-12"
            v-for="item in content.pagina_content"
            :key="item.text"
          >
            <div v-if="item.type == 'text'">
              <div class="text-component" v-html="item.text"></div>
            </div>
          </div>

          <div v-if="$store.state.userProfile.email">
            <div
              class="col-12 margin-top-sm"
              v-if="
                payments.length == 0 &&
                ($store.state.userProfile.functie == 'Cardioloog' ||
                  $store.state.userProfile.functie == 'Thorax chirurg')
              "
            >
              <router-link
                class="btn btn--primary"
                :to="content.call_to_action[0].link"
                >{{ content.call_to_action[0].button_tekst }}</router-link
              >
            </div>
          </div>
          <div v-else>
            <div
              class="col-12 margin-top-sm"

            >
              <router-link
                class="btn btn--primary"
                :to="content.call_to_action[0].link"
                >{{ content.call_to_action[0].button_tekst }}</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </fragment>
</template>

<script>
import { Fragment } from "vue-fragment";
import axios from "axios";
import Header from "@/components/Header";

export default {
  name: "OverOns",
  components: {
    Fragment,
    Header,
  },
  data() {
    return {
      payments: [],
    };
  },
  mounted() {},
  created() {
    setTimeout(() => {
      if (this.$store.state.userProfile.cid) {
        this.getCustomerPayments();
      }
      this.getInschrijvingen();
    }, 1000);
  },
  methods: {
    getCustomerPayments() {
      axios
        .get(`/api/customerPayments?cid=${this.$store.state.userProfile.cid}`)
        .then((res) => {
          if (res.status == 200) {
            this.payments = res.data.payments.filter(
              (payment) => payment.status == "paid"
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  computed: {
    content() {
      // console.log('r', this.$route.path);
      // console.log('con', this.$store.state.siteContent)
      const pageContent = this.$store.state.siteContent.filter((page) => {
        return page.uri == this.$route.path;
      });
      console.log("aa", pageContent);
      return pageContent[0];
    },
  },
};
</script>
