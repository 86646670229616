<template>
  <fragment>
    <Hero
      :introtitle="content.intro_title"
      :intro="content.intro"
      :headerafbeelding="content.header_afbeelding.permalink"
    />
    <Feature
      :featuretitel="content.feature_titel"
      :featuretekst="content.feature_tekst"
      :featureafbeelding="content.feature_afbeelding?.permalink"
    />
    <Sponsoren :sponsoren="content.children" />
  </fragment>
</template>

<script>
import { Fragment } from "vue-fragment";

import Hero from "@/components/Hero";
import Feature from "@/components/home/Feature";
import Sponsoren from "@/components/home/Sponsoren";

export default {
  name: "Home",
  components: {
    Fragment,
    Hero,
    Feature,
    Sponsoren,
  },
  mounted() {},
  computed: {
    content() {
      // console.log('r', this.$route.path);
      // console.log('con', this.$store.state.siteContent)
      const pageContent = this.$store.state.siteContent.filter((page) => {
       
        return page.uri == this.$route.path;
      }); 

      console.log(pageContent);

      return pageContent[0];
    },
  },
};
</script>
