<template>
  <section class="bg-contrast-lower min-height-100vh padding-y-sm">
    <div class="container padding-y-md max-width-lg">
      <div class="grid gap-xs justify-center">
        <div class="col-12 col-7@md padding-top-md">
          <div class="card__container padding-lg bg-white radius-md">
            <div
              class="sign-up-form"
              v-if="!registrationEmailSuccess && !error"
            >
              <div class="text-center text-component margin-bottom-sm">
                <h2 class="text-md">
                  Registreren <span class="color-primary">ledenomgeving</span>
                </h2>
                <p class="color-contrast-medium text-sm">
                  Vraag hier uw OCG account aan, een OCG lidmaatschap kost
                  <strong>€100,-</strong> per jaar voor Cardiologen en Thorax chirurgen. Voor overige functies is het lidmaatschap gratis.
                   <br /><br />
                   Zodra wij uw registratie
                  verwerkt hebben ontvangt u<br />
                  van ons bericht. Heeft u al een account? Dan kunt u hier
                  <router-link to="/login">inloggen</router-link>
                </p>
              </div>
              <form>
                <div class="margin-bottom-sm">
                  <div class="grid gap-xs">
                    <div class="col-6@md">
                      <label
                        class="form-label margin-bottom-xxxs"
                        for="input-first-name"
                        >Voornaam</label
                      >
                      <input
                        class="form-control width-100%"
                        type="text"
                        name="voornaam"
                        v-model="voornaam"
                        required
                        value=""
                        id="input-first-name"
                      />
                    </div>

                    <div class="col-6@md">
                      <label
                        class="form-label margin-bottom-xxxs"
                        for="input-last-name"
                        >Achternaam</label
                      >
                      <input
                        class="form-control width-100%"
                        type="text"
                        required
                        v-model="achternaam"
                        name="achternaam"
                        value=""
                        id="input-last-name"
                      />
                    </div>

                    <div class="col-6@md">
                      <label
                        class="form-label margin-bottom-xxxs"
                        for="input-first-name"
                        >Functie</label
                      >
                      <!-- <input
                        class="form-control width-100%"
                        type="text"
                        name="functie"
                        v-model="functie"
                        required
                        value=""
                        id="input-first-name"
                      /> -->
                    <select
                      class="form-control width-100%"
                      name="functie"
                      @change="onChangeFunction($event)"
                    >
                      <option value="0">Maak een keuze</option>
                      <option value="Cardioloog">Cardioloog</option>
                      <option value="Thorax chirurg">Thorax chirurg</option>
                       <option value="ANIOS">ANIOS</option>
                      <option value="AIOS">AIOS</option>
                      <option value="Verpleegkundig specialist">Verpleegkundig specialist</option>
                      <option value="Physician assistant">Physician assistant</option>
                      <option value="Overig">Overig</option>
                    </select>

                    </div>
                    <div class="col-6@md">
                      <label
                        class="form-label margin-bottom-xxxs"
                        for="input-last-name"
                        >Werkzaam bij</label
                      >
                      <input
                        class="form-control width-100%"
                        type="text"
                        v-model="organisatie"
                        name="organisatie"
                        required
                        value=""
                        id="input-last-name"
                      />
                    </div>
                    <div class="col-6@md">
                      <label
                        class="form-label margin-bottom-xxxs"
                        for="input-last-name"
                        >BIG nummer</label
                      >
                      <input
                        class="form-control width-100%"
                        type="text"
                        name="big_nummer"
                        v-model="big_nummer"
                        value=""
                        id="input-last-name"
                      />
                    </div>

                    <div class="col-6@md">
                      <label
                        class="form-label margin-bottom-xxxs"
                        for="input-email"
                        >E-mail adres</label
                      >
                      <input
                        class="form-control width-100%"
                        type="email"
                        name="email"
                        v-model="email"
                        required
                        id="input-email"
                        value=""
                        placeholder="naam@domein.nl"
                      />
                    </div>
                    <div class="col-12">
                      <label
                        class="form-label margin-bottom-xxxs"
                        for="input-email"
                        >Wachtwoord</label
                      >
                      <input
                        @input="checkPasswordStrength"
                        class="form-control width-100%"
                        type="password"
                        name="password"
                        v-model="password"
                        required
                        id="input-password"
                        value=""
                        placeholder="Wachtwoord"
                      />
                      <div class="color-contrast-medium text-sm margin-top-sm">
                        Wachtwoord moet minimaal 8 tekens bevatten, gebruik
                        speciale tekens en hoofdletters.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="margin-bottom-sm">
                  <!-- <button
                  :disabled="!validated"
                  @click.prevent="requestRegistration"
                  class="btn btn--primary btn--md width-100%"
                >
                
                </button> -->

                  <button
                    :disabled="!validated"
                    @click.prevent="requestRegistration"
                    class="reset btn-states width-100% btn btn--primary"
                    :class="loading && 'btn-states--state-b'"
                  >
                    <span class="btn-states__content-a">
                      Account aanvragen
                    </span>

                    <span class="btn-states__content-b inline-flex flex-center">
                      <svg
                        class="icon icon--is-spinning margin-right-xxxs"
                        aria-hidden="true"
                        viewBox="0 0 16 16"
                      >
                        <title>Loading</title>
                        <g
                          stroke-width="1"
                          fill="currentColor"
                          stroke="currentColor"
                        >
                          <path
                            d="M.5,8a7.5,7.5,0,1,1,1.91,5"
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </g>
                      </svg>
                      <span>Laden</span>
                    </span>
                  </button>
                </div>
              </form>

              <div class="text-center">
                <p class="text-xs color-contrast-medium">
                  Door u in te schrijven gaat u akkoord met onze

                  <a href="/documenten/privacyverklaring_ocg.pdf"
                    >privacy verklaring</a
                  >.
                </p>
              </div>
            </div>
            <div v-if="registrationEmailSuccess">
              <div class="text-center text-component margin-bottom-sm">
                <h2 class="text-md">
                  Registreren <span class="color-primary">ledenomgeving</span>
                </h2>
              </div>

              <div
                class="alert alert--success alert--is-visible padding-sm radius-md js-alert"
                role="alert"
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <svg
                      class="icon icon--sm alert__icon margin-right-xxs"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        d="M12,0A12,12,0,1,0,24,12,12.035,12.035,0,0,0,12,0ZM10,17.414,4.586,12,6,10.586l4,4,8-8L19.414,8Z"
                      ></path>
                    </svg>

                    <p class="text-sm">
                      <strong>Gelukt!</strong> We hebben uw aanvraag ontvangen,
                      u ontvang z.s.m. een bevestiging van uw registratie. U
                      wordt doorgestuurd naar de homepagina.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="error">
              <div class="text-center text-component margin-bottom-sm">
                <h2 class="text-md">
                  Registreren
                  <span class="color-primary">ledenomgeving mislukt</span>
                </h2>
              </div>

              <div
                class="alert alert--error alert--is-visible padding-sm radius-md js-alert"
                role="alert"
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <svg
                      class="icon icon--sm alert__icon margin-right-xxs"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        d="M12,0A12,12,0,1,0,24,12,12.035,12.035,0,0,0,12,0ZM10,17.414,4.586,12,6,10.586l4,4,8-8L19.414,8Z"
                      ></path>
                    </svg>

                    <p class="text-sm">
                      <strong>Helaas.</strong> er is iets misgegaan met uw
                      registratie. Probeer het later nog eens of neem contact op
                      met ons secretariaat via
                      <a
                        href="mailto:info@oostelijkcardiologengenootschap.nl"
                        class="color-primary"
                        >info@oostelijkcardiologengenootschap.nl</a
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import zxcvbn from "zxcvbn";

export default {
  name: "Register",

  mounted() {
    // if (this.$isAuthenticated()) {
    //   this.$router.push("/dashboard");
    // }
  },
  data() {
    return {
      loading: false,
      email: "",
      password: "",
      registrationEmailSuccess: false,
      voornaam: "",
      achternaam: "",
      functie: "",
      big_nummer: "",
      organisatie: "",
      passwordStrength: 0,
      error: false,
    };
  },
  methods: {
    onChangeFunction(e) {
      this.functie = e.target.value;
    },
    requestRegistration() {
      this.loading = true;
      axios
        .post(`/api/registrationEmail`, {
          name: `${this.voornaam} ${this.achternaam}`,
          email: this.email,
          password: this.password,
          details: {
            voornaam: this.voornaam,
            achternaam: this.achternaam,
            functie: this.functie,
            organisatie: this.organisatie,
            big_nummer: this.big_nummer,
            email: this.email,
          },
        })
        .then((res) => {
          console.log("res", res);

          if (res.status == 200) {
            this.registrationEmailSuccess = true;
            this.loading = false;
            setTimeout(() => {
              this.$router.replace("/home");
            }, 3200);
          }
        })
        .catch((error) => {
          this.registrationEmailSuccess = false;
          this.error = true;
          this.loading = false;

          setTimeout(() => {
            window.location.reload();
          }, 3200);
          console.error(error);
        });
    },
    checkPasswordStrength() {
      this.passwordStrength = zxcvbn(this.password).score;
    },
    resetLoginForm() {
      this.email = "";
      this.emailSent = false;
      this.$router.replace("/login");
    },
  },
  computed: {
    validated() {
      return (
        this.email &&
        this.password &&
        this.functie &&
        this.organisatie &&
        this.passwordStrength >= 3
      );
    },
  },
};
</script>

<style scoped>
.bg--fullscreen {
  height: calc(100vh);
  width: 100vw;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
