<template>
  <section class="paddin-y-xl">
    <div class="container max-width-adaptive-lg">
      <div class="grid gap-sm">
        <div class="col-12">
          <h1 class="margin-y-sm text-xxxl font-bold display@md">
            {{ content.title }}
          </h1>
        </div>

        <div
          v-for="(event, i) in events"
          :key="event.id"
          :class="i == 0 ? 'col-8@md' : 'col-4@md'"
        >
       
          <router-link
            :to="`activiteiten/${event.slug}`"
            class="card-v9 card-v9--overlay-bg radius-md"
            aria-labelledby="card-title-1"
            :style="i == 0 ? `background-image: url(${event.header_afbeelding?.permalink});` : `background-image: url(http://wsrv.nl?url=${event.header_afbeelding?.permalink}&w=500&h500&fit=cover);`"
          >
            <div class="card-v9__content padding-md">
              <div class="padding-bottom-xxxl max-width-xxs">
                <p
                  class="
                    text-sm
                    color-contrast-higher color-opacity-50%
                    margin-bottom-xxs
                  "
                >
                  {{ event.type }}
                </p>
                <h1 id="card-title-1" class="text-lg" style="min-height: 72px">
                  {{ event.title }}
                </h1>
                <h4 class="text-sm color-contrast-high">
                  {{ formatDate(event) }} <br /> {{ event.locatie }}
                </h4>
              </div>

              <div class="margin-top-auto">
                <span class="card-v9__btn"><i>Info & aanmelden</i></span>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { orderBy } from "lodash";
import moment from "moment";
import "moment/locale/nl"; // without this line it didn't work
moment.locale("nl");

export default {
  name: "Activiteiten",
  methods: {
    formatDate(event) {
      const starttijd = moment(event.datum).utc().format("LLLL");
      if (event.heeft_eindtijd_datum) {

        const format = event.title.includes('Jaarsymposium') ? "LLLL" : "LT";
        const eindtijd = moment(event.einddatum).utc().format(format);
        return `${starttijd.charAt(0).toUpperCase() + starttijd.slice(1)} tot ${
          eindtijd.charAt(0).toUpperCase() + eindtijd.slice(1)
        }`;
      } else {
        return starttijd.charAt(0).toUpperCase() + starttijd.slice(1);
      }
    },
  },
  computed: {
    events() {
      // console.log('r', this.$route.path);
      // console.log('con', this.$store.state.siteContent)
      const pageContent = this.$store.state.siteContent.filter((page) => {
        const path =
          this.$route.path == "/activiteiten" ? "/symposia" : this.$route.path;
        return page.uri == path;
      });
      const events = pageContent[0].children;

      return orderBy(events, "order", "asc");
    },

    content() {
      // console.log('r', this.$route.path);
      // console.log('con', this.$store.state.siteContent)
      const pageContent = this.$store.state.siteContent.filter((page) => {
        const path =
          this.$route.path == "/activiteiten" ? "/symposia" : this.$route.path;
        return page.uri == path;
      });

      return pageContent[0];
    },
  },
};
</script>
