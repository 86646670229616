import Vue from "vue";
import Router from "vue-router";
import firebase from "firebase";
import store from "../store";
import Home from "@/views/Home";
import Activiteiten from "@/views/Activiteiten";
import SymposiaDetail from "@/views/SymposiaDetail";
import OverOns from "@/views/Overons";
import Lidmaatschap from "@/views/Lidmaatschap";
import Invoice from "@/views/Invoice";
import Login from "@/views/Login";
import Register from "@/views/Register";
import Account from "@/views/Account";
import Contact from "@/views/Contact";
import Beheer from "@/views/Beheer";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "*",
      redirect: "/",
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        hideForAuth: true,
      },
    },
    {
      path: "/registreren",
      name: "Register",
      component: Register,
      meta: {
        hideForAuth: true,
      },
    },
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/activiteiten",
      name: "Activiteiten",
      component: Activiteiten,
    },
    {
      path: "/activiteiten/:slug",
      name: "SymposiaDetail",
      component: SymposiaDetail,
    },
    {
      path: "/over-ons",
      name: "Over ons",
      component: OverOns,
    },
    {
      path: "/lidmaatschap",
      name: "Lidmaatschap",
      component: Lidmaatschap,
    },
    {
      path: "/contact",
      name: "Contact",
      component: Contact,
    },
    {
      path: "/account",
      name: "Account",
      component: Account,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/beheer",
      name: "Beheer",
      component: Beheer,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/account/invoice/:id",
      name: "Factuur",
      component: Invoice,
      meta: {
        requiresAuth: true,
      },
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach((to, from, next) => {
  firebase.auth().onAuthStateChanged(function(user) {

    store.dispatch("SET_USER_PROFILE", user);

    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (!user) {
        next({ path: "/login" });
      } else {
        next();
      }
    } else {
      next();
    }

    if (to.matched.some((record) => record.meta.hideForAuth)) {
      if (user) {
        next({ path: "/" });
      } else {
        next();
      }
    } else {
      next();
    }
  });
});

export default router;
