<template>
    <section class="position-relative z-index-1 padding-y-xl@md">
  <div class="container max-width-adaptive-lg">
    <div class="grid gap-md items-center">
      <div class="col-6@md">
        <div class="text-component">
          
          <h1 class="text-xxxl font-bold display@md">{{ title }}</h1>
          <h1 class="text-xl font-bold hide@md">{{ title }}</h1>
          <div class="margin-top-md text-md">
              <p>
                  {{ omschrijving }}
              </p>
          </div>
        </div>
  
        <div class="margin-top-md">
          <span class="padding-top-lg font-normal color-primary reveal-fx reveal-fx--translate-up reveal-fx--is-visible">Scroll verder<span class="margin-left-xs"><svg version="1.1" class="icon align-middle rotate-90" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="600px" height="100%" viewBox="0 0 487 487" style="enable-background:new 0 0 487 487;" xml:space="preserve"> <g> <path d="M482,234.839l-116.5-67.261c-4.783-2.761-10.898-1.122-13.66,3.66c-2.762,4.783-1.122,10.898,3.66,13.66l84.213,48.62H10 c-5.523,0-10,4.478-10,10c0,5.521,4.477,10,10,10h429.646L355.5,302.101c-4.783,2.762-6.422,8.877-3.66,13.66 c1.853,3.208,5.213,5.002,8.669,5.002c1.696,0,3.416-0.434,4.991-1.342L482,252.159c3.094-1.786,5-5.088,5-8.66 C487,239.927,485.094,236.625,482,234.839z"></path> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg></span></span> 
        </div>
      </div>
    
      <div class="col-6@md">
        <figure>
          <img class="block width-100%" :src="headerafbeelding" :alt="title">
        </figure>
      </div>
    </div>
  </div>
</section>
</template>

<script>
export default {
  name: "Header",
  props: {
    title: String,
    omschrijving: String,
    headerafbeelding: String
  },
  computed: {
    content(){
      
      // console.log('r', this.$route.path);
      // console.log('con', this.$store.state.siteContent)
      const pageContent = this.$store.state.siteContent.filter(page => {
        return page.uri == this.$route.path;
      })

      return pageContent[0];
    }
  }
};
</script>