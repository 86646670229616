import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import axios from 'axios';
import firebase from "firebase";
const fb = require("../firebaseConfig.js");

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    siteContent: [],
    signinEmail: null,
    currentUser: null,
    userProfile: { firstname: "", lastname: "", profileImg: "", email: "" },
    courses: null,
    teachers: null,
    topics: null,
    updates: null,
    userCourse: null,
    user_calendar: [],
    class_calendar: [],
    exams: [],
    modalVisible: false,
    currentCourse: {
      meta: null,
      courseWork: null,
      courseWorkMaterial: null,
      courseWorkSubmissions: null,
    },
  },
  getters: {
    IS_AUTHENTICATED: (state) => state.currentUser !== null,
    SIGNIN_EMAIL: (state) => state.signinEmail !== null,
  },
  mutations: {
    SET_CURRENT_USER(state, val) {
      state.currentUser = val;
    },
    SET_USER_PROFILE(state, val) {
      
      state.userProfile = {
        organisatie: val.organisatie,
        big_nummer: val.big_nummer,
        naam: val.naam,
        voornaam: val.naam?.split(' ')[0],
        achternaam: val.naam?.split(' ')[1],
        functie: val.functie,
        role: val.role,
        email: val.email,
        cid: val.cid
      };
      //console.log(state.userProfile);
    },
    SET_COURSES(state, val) {
      state.userCourse = val;
    },
    SET_COURSE_META(state, val) {
      state.currentCourse.meta = val;
    },
    SET_COURSEWORK(state, val) {
      state.currentCourse.courseWork = val.courseWork;
    },
    SET_COURSEWORKMATERIAL(state, val) {
      state.currentCourse.courseWorkMaterial = val.courseWorkMaterial;
    },
    SET_STUDENTSUBMISSIONS(state, val) {
      state.currentCourse.courseWorkSubmissions = val.studentSubmissions;
    },
    SET_CLASS_CALENDAR_ITEMS(state, val) {
      state.class_calendar = val;
    },

    SET_USER_CALENDAR_ITEMS(state, val) {
      state.user_calendar = val;
    },
    SET_TEACHERS(state, val) {
      state.teachers = val;
    },
    SET_TOPICS(state, val) {
      state.topics = val;
    },
    SET_EXAMS(state, val) {
      state.exams = val
    }
  },
  actions: {
    INIT_CONTENT ({state}, payload) {
      state.siteContent = payload;
    },

    SET_HEADER_VISIBILITY({ state }, payload) {
      state.modalVisible = payload.modalVisible;
    },
    FETCH_USER_PROFILE({ commit, state }) {
      const currentUser = firebase.auth().currentUser;
      let data = null;
      let customerData = null;

      const user = fb.usersCollection.where("email", "==", currentUser.email)
      .get()
      .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (doc.data()) {
              data = doc.data();
            }
          });
          return data;
      })
      .catch((error) => {
          console.log("Error getting documents: ", error);
      });
      const customer = fb.customersCollection.where("email", "==", currentUser.email)
      .get()
      .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (doc.data()) {
              customerData = doc.data();
            }
          });
          return customerData;
      })
      .catch((error) => {
          console.log("Error getting documents: ", error);
      });

      Promise.all([user, customer]).then((data) => {
        const userWithCustomer = { ...data[0], ...data[1] };
  
        commit("SET_USER_PROFILE", userWithCustomer);
        
    });
      
    },
    SET_USER_PROFILE({ dispatch, commit, state }, payload) {
      //const currentUser = firebase.auth().currentUser;
      console.log(payload);

    
      if (payload) {
        dispatch("FETCH_USER_PROFILE");
      } else {
        dispatch("LOGOUT");
      }

      // fb.usersCollection
      //   .doc(state.currentUser.uid)
      //   .get()
      //   .then((res) => {

      //     commit("SET_USER_PROFILE", res.data());
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },




    LOGOUT(_) {

      fb.auth
        .signOut()
        .then(function() {
          // Sign-out successful.
         // console.log("logged out");
          //router.replace({ path: "login" });
        })
        .catch(function(error) {
          console.log("logout error", error);
          alert("er is iets fout gegaan");
        });
    },
  },
  modules: {},
});
