<template>
  <header class="f-header js-f-header">
    <div class="f-header__mobile-content container max-width-lg">
      <a href="/" class="f-header__logo font-bold text-md">
        <span class="margin-right-xxs display@md">
          <img src="../assets/logo.png" width="80" /></span
        ><span class="text-sm wordmark display@md"
          >Oostelijk Cardiologen<br />Genootschap</span
        ><img
          class="hide@md"
          src="https://ocg.strakk.digital/assets/logo.png"
          width="60"
        />
      </a>
      <button
        class="reset anim-menu-btn js-anim-menu-btn js-tab-focus f-header__nav-control"
        aria-label="Toggle menu"
        style="outline: none"
      >
        <i
          class="anim-menu-btn__icon anim-menu-btn__icon--close"
          aria-hidden="true"
        ></i>
      </button>
    </div>
    <div class="f-header__nav" role="navigation">
      <div class="f-header__nav-grid container max-width-lg">
        <div class="f-header__nav-logo-wrapper margin-right-lg has-margin@md">
          <a href="/" class="f-header__logo font-bold text-lg">
            <span class="margin-right-xxs display@md">
              <img
                src="https://ocg.strakk.digital/assets/logo.png"
                width="80" /></span
            ><span class="text-sm wordmark display@md"
              >Oostelijk Cardiologen<br />Genootschap</span
            ><img
              class="hide@md"
              src="https://ocg.strakk.digital/assets/logo.png"
              width="60"
            />
          </a>
        </div>
        <ul class="f-header__list margin-left-auto has-margin@md">
          <li class="f-header__item text-base">
            <router-link to="/" aria-current="page" class="f-header__link"
              >Home</router-link
            >
          </li>
          <li class="f-header__item text-base">
            <router-link to="/activiteiten" class="f-header__link"
              >Activiteiten</router-link
            >
          </li>
          <li class="f-header__item text-base">
            <router-link to="/over-ons" class="f-header__link"
              >Over ons</router-link
            >
          </li>
          <li class="f-header__item text-base">
            <router-link to="/lidmaatschap" class="f-header__link"
              >Lidmaatschap</router-link
            >
          </li>
          <li class="f-header__item text-base">
            <router-link to="/contact" class="f-header__link"
              >Contact</router-link
            >
          </li>
        </ul>
        <ul class="f-header__list flex-grow flex-basis-0 justify-end@md">
          <li v-if="$store.state.userProfile.email" class="f-header__item">
            <div class="flex items-center gap-xxxxs position-relative">
              <span class="align-middle text-sm margin-right-sm">
                {{ $store.state.userProfile.email }}
              </span>
              <div class="dropdown inline-block js-dropdown">
                <div class="dropdown__wrapper">
                  <a
                    class="dropdown__trigger inline-flex items-center js-dropdown__trigger"
                    href="#0"
                  >
                    <div class="avatar avatar--md">
                      <figure
                        @click="toggleDropDown"
                        class="avatar__figure"
                        role="img"
                        aria-label="James Powell"
                      >
                        <svg
                          class="avatar__placeholder"
                          aria-hidden="true"
                          viewBox="0 0 20 20"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <circle
                            cx="10"
                            cy="6"
                            r="2.5"
                            stroke="currentColor"
                          />
                          <path
                            d="M10,10.5a4.487,4.487,0,0,0-4.471,4.21L5.5,15.5h9l-.029-.79A4.487,4.487,0,0,0,10,10.5Z"
                            stroke="currentColor"
                          />
                        </svg>
                        <div class="avatar__initials">
                          <span>{{
                            $store.state.userProfile.email
                              .charAt(0)
                              .toUpperCase()
                          }}</span>
                        </div>
                      </figure>
                    </div>
                  </a>

                  <ul
                    class="dropdown__menu js-dropdown__menu"
                    aria-label="dropdown"
                  >
                    <li>
                      <router-link to="/account" class="dropdown__item"
                        >Mijn gegevens</router-link
                      >
                    </li>
                    <li v-if="$store.state.userProfile.role == 'admin'">
                      <router-link to="/beheer" class="dropdown__item"
                        >Beheer</router-link
                      >
                    </li>
                    <hr class="dropdown__separator" role="separator" />

                    <li>
                      <a class="dropdown__item" @click.prevent="logout"
                        >Uitloggen</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>

          <li v-if="!$store.state.userProfile.email" class="f-header__item">
            <router-link
              to="/login"
              class="f-header__btn btn btn--sm btn--primary"
              >Inloggen voor leden</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import firebase from "firebase";

export default {
  name: "Nav",
  data() {
    return {
      accountDropdownVisible: false,
    };
  },
  mounted() {
    let frontEnd = document.createElement("script");
    frontEnd.setAttribute("src", "./js/dropdown.js");

    document.body.appendChild(frontEnd);
  },

  methods: {
    toggleDropDown() {
      this.accountDropdownVisible = !this.accountDropdownVisible;
    },

    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.user = null;
          this.accountDropdownVisible = false;
          setTimeout(() => {
            window.location.reload();
          }, 400);
        })
        .catch((error) => {});
    },
  },
};
</script>
