<template>
  <section class="padding-y-xl">
    <div class="container max-width-lg">
      <nav class="s-tabs margin-bottom-md">
        <ul class="s-tabs__list">
          <li>
            <a
              class="s-tabs__link cursor-pointer"
              :class="activeTab == 'account' && 's-tabs__link--current'"
              @click="toggleTab('account')"
              >Lidmaatschap</a
            >
          </li>
          <li>
            <a
              class="s-tabs__link cursor-pointer"
              :class="activeTab == 'profiel' && 's-tabs__link--current'"
              @click="toggleTab('profiel')"
              >Profielgegevens</a
            >
          </li>
          <li>
            <a
              class="s-tabs__link cursor-pointer"
              :class="activeTab == 'inschrijvingen' && 's-tabs__link--current'"
              @click="toggleTab('inschrijvingen')"
              >Mijn inschrijvingen</a
            >
          </li>
        </ul>
      </nav>

      <div class="grid gap-md" v-if="activeTab == 'account'">
        <div class="col-12 col-8@md">
          <div class="table-card bg radius-md padding-md shadow-xs">
            <div class="margin-bottom-md">
              <div class="flex items-baseline justify-between">
                <p class="color-contrast-medium">Betalingen</p>
                <!-- <p><a class="text-sm" href="#0">View All &rarr;</a></p> -->
              </div>
            </div>

            <div class="tbl text-sm">
              <div v-if="payments.length == 0" class="">
                <p class="color-contrast-medium">Geen betalingen gevonden</p>
              </div>
              <table class="tbl__table" aria-label="Table Example">
                <thead class="tbl__header sr-only">
                  <tr class="tbl__row">
                    <th class="tbl__cell text-left" scope="col">
                      <span
                        class="text-xs text-uppercase letter-spacing-lg font-semibold"
                        >Bedrag</span
                      >
                    </th>

                    <th class="tbl__cell text-left" scope="col">
                      <span
                        class="text-xs text-uppercase letter-spacing-lg font-semibold"
                        >Omschrijving</span
                      >
                    </th>

                    <th class="tbl__cell text-right" scope="col">
                      <span
                        class="text-xs text-uppercase letter-spacing-lg font-semibold"
                        >Status</span
                      >
                    </th>
                  </tr>
                </thead>

                <tbody class="tbl__body">
                  <tr
                    class="tbl__row"
                    :key="payment.id"
                    v-for="payment in payments"
                  >
                    <td class="tbl__cell" role="cell">
                      {{ formatCurrency(payment.amount.value) }}
                    </td>
                    <td class="tbl__cell" role="cell">
                      <span class="color-contrast-medium">{{
                        payment.description
                      }}</span>
                    </td>

                    <td class="tbl__cell" role="cell">
                      <div class="flex justify-end">
                        <div
                          v-if="payment.status == 'paid'"
                          class="progress-bar inline-flex items-center js-progress-bar"
                        >
                          <span
                            class="badge badge--success-light color-success text-sm"
                            >Voldaan</span
                          >

                          <div
                            class="progress-bar__bg order-1"
                            aria-hidden="true"
                          >
                            <div
                              class="progress-bar__fill color-error"
                              style="width: 30%"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="tbl__cell" role="cell">
                      <router-link append :to="`invoice/${payment.id}`"
                        >Factuur</router-link
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-4@md">
          <div
            v-if="payments.length == 0 && (this.$store.state.userProfile.functie == 'Cardioloog' || this.$store.state.userProfile.functie == 'Thorax chirurg')"
            class="alert alert--warning alert--is-visible padding-sm radius-md js-alert"
            role="alert"
          >
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <svg
                  class="icon icon--sm alert__icon margin-right-xxs"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12s12-5.383,12-12S18.617,0,12,0z M13.645,5L13,14h-2l-0.608-9 H13.645z M12,20c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2c1.105,0,2,0.895,2,2C14,19.105,13.105,20,12,20z"
                  ></path>
                </svg>

                <p class="text-sm">
                  <strong>Let op:</strong> om uw account te activeren dient u
                  ons te machtigen om jaarlijks het lidmaatschapsgeld à
                  <strong>€100,-</strong> af te schrijven.
                  <br />
                  <br />
                  <span class="cursor-pointer color-error"
                    @click.prevent="createCustomer"
                    >Klik hier</span>
                  om doorgestuurd te worden naar onze betaalprovider.
                  U betaalt 0,01 cent om uw rekening te verifiëren, vervolgens wordt het jaarlijkse
                  lidmaatschapsgeld automatisch afgeschreven.
                </p>
              </div>
            </div>
          </div>

          <div
            v-else
            class="alert alert--success alert--is-visible padding-sm radius-md js-alert"
            role="alert"
          >
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <svg
                  class="alert__icon icon icon--sm margin-right-xxs"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <g fill="currentColor">
                    <circle
                      cx="12"
                      cy="12"
                      r="12"
                      opacity=".2"
                      style="isolation: isolate"
                    ></circle>
                    <path
                      d="M9.5 17a1 1 0 0 1-.707-.293l-3-3a1 1 0 0 1 1.414-1.414L9.5 14.586l7.293-7.293a1 1 0 1 1 1.439 1.389l-.025.025-8 8A1 1 0 0 1 9.5 17z"
                    ></path>
                  </g>
                </svg>
                <p class="text-sm">
                  Uw lidmaatschap is momenteel actief. U kunt uw lidmaatschap
                  stopzetten door contact met ons op te nemen.
                  <br />
                  <br />
                  <a
                    href="mailto:kim@dezaakgeregeld.nl?subject=Stopzetten lidmaatschap OCG"
                    class="cursor-pointer"
                    >Neem contact op</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="grid gap-md" v-if="activeTab == 'profiel'">
        <div class="col-12 col-8@md">
          <div class="table-card bg radius-md padding-md shadow-xs">
            <div class="margin-bottom-sm">
              <div class="grid gap-xs">
                <div class="col-6@md">
                  <label
                    class="form-label margin-bottom-xxxs"
                    for="input-first-name"
                    >Voornaam</label
                  >

                  <input
                    class="form-control width-100%"
                    type="text"
                    name="voornaam"
                    required=""
                    v-model="voornaam"
                    :placeholder="$store.state.userProfile.naam.split(' ')[0]"
                    id="input-first-name"
                  />
                </div>

                <div class="col-6@md">
                  <label
                    class="form-label margin-bottom-xxxs"
                    for="input-last-name"
                    >Achternaam</label
                  >

                  <input
                    class="form-control width-100%"
                    type="text"
                    required=""
                    name="achternaam"
                    v-model="achternaam"
                    :placeholder="$store.state.userProfile.naam.split(' ')[1]"
                    id="input-last-name"
                  />
                </div>

                <div class="col-6@md">
                  <label
                    class="form-label margin-bottom-xxxs"
                    for="input-first-name"
                    >Functie</label
                  >
                  <!-- <input
                    class="form-control width-100%"
                    type="text"
                    name="functie"
                    required=""
                    v-model="functie"
                    :placeholder="$store.state.userProfile.functie"
                    id="input-first-name"
                  /> -->

                  <div class="select">
                    <select
                      class="form-control width-100%"
                      name="functie"
                      @change="onChangeFunction($event)"
                    >
                      <option value="0">Maak een keuze</option>

                      <option
                        :selected="
                          $store.state.userProfile.functie == 'Cardioloog'
                        "
                        value="Cardioloog"
                      >
                        Cardioloog
                      </option>
                      <option
                        :selected="
                          $store.state.userProfile.functie == 'Thorax chirurg'
                        "
                        value="Thorax chirurg"
                      >
                        Thorax chirurg
                      </option>
                      <option
                        :selected="$store.state.userProfile.functie == 'ANIOS'"
                        value="ANIOS"
                      >
                        ANIOS
                      </option>
                      <option
                        :selected="$store.state.userProfile.functie == 'AIOS'"
                        value="AIOS"
                      >
                        AIOS
                      </option>
                      <option
                        :selected="
                          $store.state.userProfile.functie ==
                          'Verpleegkundig specialist'
                        "
                        value="Verpleegkundig specialist"
                      >
                        Verpleegkundig specialist
                      </option>
                      <option
                        :selected="
                          $store.state.userProfile.functie ==
                          'Physician assistant'
                        "
                        value="Physician assistant"
                      >
                        Physician assistant
                      </option>
                      <option
                        :selected="$store.state.userProfile.functie == 'Overig'"
                        value="Overig"
                      >
                        Overig
                      </option>
                    </select>

                    <svg
                      class="icon select__icon"
                      aria-hidden="true"
                      viewBox="0 0 16 16"
                    >
                      <polyline
                        points="1 5 8 12 15 5"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                    </svg>
                  </div>
                </div>
                <div class="col-6@md">
                  <label
                    class="form-label margin-bottom-xxxs"
                    for="input-last-name"
                    >Werkzaam bij</label
                  >
                  <input
                    class="form-control width-100%"
                    type="text"
                    name="organisatie"
                    v-model="organisatie"
                    :placeholder="$store.state.userProfile.organisatie"
                    id="input-last-name"
                  />
                </div>
                <div class="col-6@md">
                  <label
                    class="form-label margin-bottom-xxxs"
                    for="input-last-name"
                    >BIG nummer</label
                  >
                  <input
                    class="form-control width-100%"
                    type="text"
                    name="big_nummer"
                    v-model="big_nummer"
                    :placeholder="$store.state.userProfile.big_nummer"
                    id="input-last-name"
                  />
                </div>

                <div class="col-6@md">
                  <label class="form-label margin-bottom-xxxs" for="input-email"
                    >E-mail adres</label
                  >
                  <input
                    class="form-control width-100%"
                    type="email"
                    name="email"
                    required=""
                    v-model="email"
                    id="input-email"
                    :placeholder="$store.state.userProfile.email"
                  />
                </div>
              </div>
            </div>

            <div class="margin-bottom-sm">
              <div class="col-5@md">
                <div
                  v-if="updated"
                  class="padding-xs text-sm bg-contrast-low border margin-bottom-sm radius-lg"
                >
                  Uw gegevens zijn bijgewerkt.
                </div>
                <button
                  @click.prevent="updateUser"
                  class="reset btn-states width-100% btn btn--primary"
                  :class="updating && 'btn-states--state-b'"
                >
                  <span class="btn-states__content-a"> Gegevens wijzigen </span>

                  <span class="btn-states__content-b inline-flex flex-center">
                    <svg
                      class="icon icon--is-spinning margin-right-xxxs"
                      aria-hidden="true"
                      viewBox="0 0 16 16"
                    >
                      <title>Loading</title>
                      <g
                        stroke-width="1"
                        fill="currentColor"
                        stroke="currentColor"
                      >
                        <path
                          d="M.5,8a7.5,7.5,0,1,1,1.91,5"
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </g>
                    </svg>
                    <span>Laden</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="grid gap-md" v-if="activeTab == 'inschrijvingen'">
        <div class="col-12 col-8@md">
          <div
            class="table-card bg radius-md padding-md shadow-xs"
            v-if="inschrijvingen.length"
          >
            <div v-for="inschrijving in inschrijvingen" :key="inschrijving.id">
              <div class="grid gap-xs">
                <div class="col-12">
                  <p class="margin-bottom-xxxs">
                    <router-link :to="`/activiteiten/${bijeenkomstSlug}`">
                      <strong> {{ inschrijving.bijeenkomst }}</strong>
                      {{ formatDate(inschrijving.datum) }}
                    </router-link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="table-card bg radius-md padding-md shadow-xs" v-else>
            <p>
              U heeft zich nog niet ingeschreven voor een bijeenkomst. Bekijk
              onze <router-link to="/activiteiten">activiteiten</router-link> om
              u in te schrijven.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
const fb = require("../firebaseConfig.js");
import axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      updated: false,
      activeTab: "account",
      updating: false,
      payments: [],
      inschrijvingen: [],
      voornaam: this.$store.state.userProfile.voornaam,
      achternaam: this.$store.state.userProfile.achternaam,
      functie: this.$store.state.userProfile.functie,
      big_nummer: this.$store.state.userProfile.big_nummer,
      organisatie: this.$store.state.userProfile.organisatie,
      email: this.$store.state.userProfile.email,
    };
  },
  created() {
    setTimeout(() => {
      if (this.$store.state.userProfile.cid) {
        this.getCustomerPayments();
      }
        this.getInschrijvingen();
    }, 1000);
  },

  methods: {
    onChangeFunction(e) {
      this.functie = e.target.value;
    },
    formatDate(date) {
      return moment(date).utc().format("LLLL");
    },
    getInschrijvingen() {

      fb.inschrijvingCollection
        .where("email", "==", this.$store.state.userProfile.email)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
           
            this.inschrijvingen.push(doc.data());
          });
        })
        .catch((error) => {
          console.error("Error getting documents: ", error);
        });
    },
    toggleTab(tab) {
      this.activeTab = tab;
    },
    createCustomer() {
      axios
        .post(`/api/createMollieCustomer`, {
          name: this.$store.state.userProfile.name,
          email: this.$store.state.userProfile.email,
        })
        .then((res) => {
          if (res.status == 200) {
            setTimeout(() => {
              window.open(res.data.checkout, "_blank");
            }, 100);
          }
        })
        .catch((error) => {
          this.registrationEmailSuccess = false;
          console.error(error);
        });
    },
    getCustomerPayments() {
      axios
        .get(`/api/customerPayments?cid=${this.$store.state.userProfile.cid}`)
        .then((res) => {
          if (res.status == 200) {
            this.payments = res.data.payments.filter(
              (payment) => payment.status == "paid"
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async updateUser() {
      this.updating = true;
      const user = this.removeEmptyValues({
        voornaam: this.voornaam,
        achternaam: this.achternaam,
        naam: `${this.voornaam} ${this.achternaam}`,
        functie: this.functie,
        organisatie: this.organisatie,
        big_nummer: this.big_nummer,
        email: this.email,
      });

      this.querySnapshot = await fb.usersCollection
        .where("email", "==", this.$store.state.userProfile.email)
        .get();

      this.querySnapshot.forEach(async (doc) => {
        await doc.ref.update({
          ...user,
        });

        this.updating = false;
        this.updated = true;

        setTimeout(() => {
          this.updated = false;
        }, 1000);
      });
    },
    formatCurrency(amount) {
      return new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      }).format(amount);
    },
    removeEmptyValues(object) {
      for (var key in object) {
        if (object.hasOwnProperty(key)) {
          var value = object[key];
          if (value === null || value === undefined || value === "") {
            delete object[key];
          }
        }
      }
      return object;
    },
  },
};
</script>
