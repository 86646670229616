<template>
  <footer class="main-footer padding-y-lg margin-top-lg" data-theme="primary">
    <div class="container max-width-lg">
      <div class="main-footer__content">
        <div class="main-footer__logo">
          <a class="no-underline font-bold text-md" href="/">
            <span class="margin-right-xxs display@md">
              <img src="https://ocg.strakk.digital/assets/logo.png" width="70"/></span
            ><span class="text-xs wordmark display@md color-white"
              >Oostelijk Cardiologen<br />Genootschap</span
            ><img class="hide@md" src="https://ocg.strakk.digital/assets/logo.png" width="60" />
          </a>
          
        </div>
        <nav class="main-footer__nav">
          <ul class="main-footer__nav-list">
            <li class="main-footer__nav-item">
              <h4>Contactgegevens</h4>
              <div class="text-xs text-sm@md">
                <p class="margin-bottom-md">
                  Klaashuisstraat 17<br />
                  7481 EN Haaksbergen<br />
                  KVK: 08111760<br />
                </p>
                <p>
                  <a href="tel:"></a><br />
                  <a href="mailto:info@oostelijkcardiologengenootschap.nl"
                    >info@oostelijkcardiologengenootschap.nl</a
                  >
                </p>
              </div>
            </li>
            <li class="main-footer__nav-item"></li>
            <li class="main-footer__nav-item">
              <h4>Navigatie</h4>
              <div><router-link to="/" class="text-xs text-base@md">Home</router-link></div>
              <div>
                <router-link to="/activiteiten" class="text-xs text-base@md">Activiteiten</router-link>
              </div>
              <div>
                <router-link to="/over-ons" class="text-xs text-base@md">Over ons</router-link>
              </div>
              <div>
                <router-link to="/contact" class="text-xs text-base@md">Contact</router-link>
              </div>
            </li>
            <li class="main-footer__nav-item">
              <h4>Overig</h4>
 
              <div>
                <a href="/documenten/privacyverklaring_ocg.pdf" target="_blank" class="text-xs text-base@md">Privacy verklaring</a>
              </div>
            </li>
          </ul>
        </nav>
      </div>
      <div class="main-footer__colophon">
        <div class="main-footer__colophon-nav">
          <span>© Oostelijk Cardiologen Genootschap (OCG)</span> <br />
          <br />
          <!-- <a
            class="text-xs text-base@md"
            href="https://strakk.digital"
            target="_blank"
            >Realisatie: strakk digital</a
          > -->
        </div>
        <div class="main-footer__socials">
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  components: {},
  mounted() {},
  methods: {
    nextStep() {
      this.$store.dispatch("nextStep");
    },
    prevStep() {
      this.$store.dispatch("prevStep");
    },
  },
};
</script>
