<template>
  <fragment>
    <Header
      :title="content.title"
      :omschrijving="content.korte_omschrijving"
      :headerafbeelding="content.header_afbeelding.permalink"
    />

    <section class="team padding-y-xl">
      <div class="container max-width-adaptive-md">
        <div class="margin-bottom-lg"><h1 class="text-center">Bestuur</h1></div>
        <div class="grid gap-sm justify-center">
          <div class="card-v2 radius-md col-4@md" v-for="lid in content.leden" :key="lid.naam">
            <figure>
              <img
                :src="`https://wsrv.nl/?url=${lid.profielfoto?.permalink}&w=320&h=320&fit=cover`"
                :alt="`Profielfoto ${lid.naam}`"
              />
              <figcaption
                class="card-v2__caption padding-x-sm padding-top-md padding-bottom-sm text-center"
              >
                <div class="text-md text-base@md">{{ lid.naam }}</div>
                <!-- <div class="margin-top-xxxs text-sm opacity-70%">
                  {{ lid.functie }}
                </div> -->
                <div class="margin-top-xxxs text-sm opacity-70%">
                {{ lid.functie }}
                </div>
              </figcaption>
            </figure>
          </div>

        </div>
      </div>
    </section>
  </fragment>
</template>

<script>
import { Fragment } from "vue-fragment";

import Header from "@/components/Header";

export default {
  name: "OverOns",
  components: {
    Fragment,
    Header,
  },
  mounted() {},
  computed: {
    content() {
      // console.log('r', this.$route.path);
      // console.log('con', this.$store.state.siteContent)
      const pageContent = this.$store.state.siteContent.filter((page) => {
        return page.uri == this.$route.path;
      });
      console.log(pageContent);
      return pageContent[0];
    },
  },
};
</script>
