<template>
  <div id="app">
    <Nav v-if="!$route.path.includes('invoice')" />
    <router-view />
    <Footer v-if="!$route.path.includes('invoice')" />
  </div>
</template>

<script>
import Nav from "@/components/Nav";
import Footer from "@/components/Footer";

export default {
  name: "app",
  components: {
    Nav,
    Footer
  },

};
</script>

<style lang="scss">
@import "assets/sass/app.scss";
</style>
