<template>
  <div class="container max-width-lg">
    <div class="tabs">
      <button
        @click="activeTab = 'leden'"
        :class="{ active: activeTab === 'leden' }"
        class="tab-button"
      >
        Overzicht Leden
      </button>
      <button
        @click="activeTab = 'inschrijvingen'"
        :class="{ active: activeTab === 'inschrijvingen' }"
        class="tab-button"
      >
        Overzicht Inschrijvingen
      </button>
    </div>

    <!-- Leden Overview -->
    <div v-if="activeTab === 'leden'" class="grid gap-xs">
      <div class="col-12">
        <div class="tbl">
          <div
            class="int-table-actions padding-bottom-xxxs border-bottom border-contrast-lower padding-y-md"
          >
            <h3>Overzicht leden</h3>
          </div>
          <div id="table-leden" class="int-table text-sm js-int-table">
            <div class="int-table__inner">
              <table class="int-table__table" aria-label="Overzicht Leden">
                <thead class="int-table__header js-int-table__header">
                  <tr class="int-table__row">
                    <th class="int-table__cell">ID</th>
                    <th
                      class="int-table__cell int-table__cell--sort"
                      @click="sortBy('leden', 'naam')"
                    >
                      Naam
                    </th>
                    <th
                      class="int-table__cell int-table__cell--sort"
                      @click="sortBy('leden', 'email')"
                    >
                      E-mail adres
                    </th>
                    <th class="int-table__cell int-table__cell--sort">
                      <div>
                        Functie
                        <select v-model="filters.functie" class="filter-dropdown">
                          <option value="">Alle Functies</option>
                          <option
                            v-for="functie in functieOptions"
                            :key="functie"
                            :value="functie"
                          >
                            {{ functie }}
                          </option>
                        </select>
                      </div>
                    </th>
                    <th class="int-table__cell int-table__cell--sort">
                      <div>
                        Organisatie
                        <select
                          v-model="filters.organisatie"
                          class="filter-dropdown"
                        >
                          <option value="">Alle Organisaties</option>
                          <option
                            v-for="organisatie in organisatieOptions"
                            :key="organisatie"
                            :value="organisatie"
                          >
                            {{ organisatie }}
                          </option>
                        </select>
                      </div>
                    </th>
                    <th
                      class="int-table__cell int-table__cell--sort"
                      @click="sortBy('leden', 'big_nummer')"
                    >
                      BIG nummer
                    </th>
                    <th>Betaling Status</th>
                    <th>Accountstatus</th>
                    <th>Actie</th>
                  </tr>
                </thead>
                <tbody class="int-table__body">
                  <tr
                    class="int-table__row"
                    v-for="(record, index) in filteredAndSortedLedenRecords"
                    :key="record.id"
                  >
                    <td class="int-table__cell">{{ index + 1 }}</td>
                    <td class="int-table__cell">{{ record.naam }}</td>
                    <td class="int-table__cell">{{ record.email }}</td>
                    <td class="int-table__cell">{{ record.functie }}</td>
                    <td class="int-table__cell">{{ record.organisatie }}</td>
                    <td class="int-table__cell">{{ record.big_nummer }}</td>
                    <td class="int-table__cell">
                      <span
                        v-if="paymentActive(record.email)"
                        class="inline-block text-sm bg-success bg-opacity-20% color-success-darker radius-full padding-y-3xs padding-x-xs ws-nowrap"
                      >
                        Actief
                      </span>
                      <span
                        v-else
                        class="inline-block text-sm bg-error bg-opacity-20% color-error-darker radius-full padding-y-3xs padding-x-xs ws-nowrap"
                      >
                        Inactief
                      </span>
                    </td>
                    <td class="int-table__cell">
                      <span v-if="record.userActive">Actief</span>
                      <span v-else>Inactief</span>
                    </td>
                    <td class="int-table__cell">
                      <button
                        @click="disableUser(record.email)"
                        class="btn btn--sm bg-contrast-lower radius-full"
                      >
                        Deactiveren
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Inschrijvingen Overview -->
    <div v-if="activeTab === 'inschrijvingen'" class="grid gap-xs">
      <div class="col-12">
        <div class="tbl">
          <div
            class="int-table-actions padding-bottom-xxxs border-bottom border-contrast-lower padding-y-md"
          >
            <h3>Overzicht Inschrijvingen</h3>
          </div>
          <div id="table-inschrijvingen" class="int-table text-sm js-int-table">
            <div class="int-table__inner">
              <table
                class="int-table__table"
                aria-label="Overzicht Inschrijvingen"
              >
                <thead class="int-table__header js-int-table__header">
                  <tr class="int-table__row">
                    <th class="int-table__cell">ID</th>
                    <th class="int-table__cell int-table__cell--sort">
                      <div>
                        Bijeenkomst Titel
                        <select
                          v-model="filters.bijeenkomst"
                          class="filter-dropdown"
                        >
                          <option value="">Alle Bijeenkomsten</option>
                          <option
                            v-for="bijeenkomst in bijeenkomstOptions"
                            :key="bijeenkomst"
                            :value="bijeenkomst"
                          >
                            {{ bijeenkomst }}
                          </option>
                        </select>
                      </div>
                    </th>
                    <th
                      class="int-table__cell int-table__cell--sort"
                      @click="sortBy('inschrijvingen', 'voornaam')"
                    >
                      Naam
                    </th>
                    <th
                      class="int-table__cell int-table__cell--sort"
                      @click="sortBy('inschrijvingen', 'achternaam')"
                    >
                      Achternaam
                    </th>
                    <th
                      class="int-table__cell int-table__cell--sort"
                      @click="sortBy('inschrijvingen', 'email')"
                    >
                      E-mail adres
                    </th>
                    <th
                      class="int-table__cell int-table__cell--sort"
                      @click="sortBy('inschrijvingen', 'functie')"
                    >
                      Functie
                    </th>
                    <th
                      class="int-table__cell int-table__cell--sort"
                      @click="sortBy('inschrijvingen', 'organisatie')"
                    >
                      Organisatie
                    </th>
                    <th
                      class="int-table__cell int-table__cell--sort"
                      @click="sortBy('inschrijvingen', 'datum')"
                    >
                      Datum
                    </th>
                    <th>Opmerkingen</th>
                  </tr>
                </thead>
                <tbody class="int-table__body">
                  <tr
                    class="int-table__row"
                    v-for="(inschrijving, index) in filteredAndSortedInschrijvingRecords"
                    :key="inschrijving.id"
                  >
                    <td class="int-table__cell">{{ index + 1 }}</td>
                    <td class="int-table__cell">{{ inschrijving.bijeenkomst }}</td>
                    <td class="int-table__cell">{{ inschrijving.voornaam }}</td>
                    <td class="int-table__cell">{{ inschrijving.achternaam }}</td>
                    <td class="int-table__cell">{{ inschrijving.email }}</td>
                    <td class="int-table__cell">{{ inschrijving.functie }}</td>
                    <td class="int-table__cell">{{ inschrijving.organisatie }}</td>
                    <td class="int-table__cell">{{ formatDate(inschrijving.datum) }}</td>
                    <td class="int-table__cell">
                      {{ inschrijving.opmerkingen || "Geen opmerkingen" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import moment from "moment";

export default {
  data() {
    return {
      activeTab: "leden", // Default tab
      ledenRecords: [],
      inschrijvingRecords: [],
      customers: [],
      filters: {
        functie: "",
        organisatie: "",
        bijeenkomst: "",
      },
      sortKey: {
        leden: "",
        inschrijvingen: "",
      },
      sortOrder: {
        leden: "asc",
        inschrijvingen: "asc",
      },
    };
  },
  computed: {
    functieOptions() {
      return [...new Set(this.ledenRecords.map((record) => record.functie))];
    },
    organisatieOptions() {
      return [...new Set(this.ledenRecords.map((record) => record.organisatie))];
    },
    bijeenkomstOptions() {
      return [
        ...new Set(this.inschrijvingRecords.map((record) => record.bijeenkomst)),
      ];
    },
    filteredAndSortedLedenRecords() {
      let filteredRecords = this.ledenRecords.filter((record) => {
        return (
          (this.filters.functie === "" ||
            record.functie === this.filters.functie) &&
          (this.filters.organisatie === "" ||
            record.organisatie === this.filters.organisatie)
        );
      });

      if (!this.sortKey.leden) return filteredRecords;
      return filteredRecords.slice().sort((a, b) => {
        const modifier = this.sortOrder.leden === "asc" ? 1 : -1;
        if (a[this.sortKey.leden] < b[this.sortKey.leden]) return -1 * modifier;
        if (a[this.sortKey.leden] > b[this.sortKey.leden]) return 1 * modifier;
        return 0;
      });
    },
    filteredAndSortedInschrijvingRecords() {
      let filteredRecords = this.inschrijvingRecords.filter((record) => {
        return (
          this.filters.bijeenkomst === "" ||
          record.bijeenkomst === this.filters.bijeenkomst
        );
      });

      if (!this.sortKey.inschrijvingen) return filteredRecords;
      return filteredRecords.slice().sort((a, b) => {
        const modifier = this.sortOrder.inschrijvingen === "asc" ? 1 : -1;
        if (a[this.sortKey.inschrijvingen] < b[this.sortKey.inschrijvingen])
          return -1 * modifier;
        if (a[this.sortKey.inschrijvingen] > b[this.sortKey.inschrijvingen])
          return 1 * modifier;
        return 0;
      });
    },
  },
  mounted() {
    this.fetchLedenRecords();
    this.fetchInschrijvingRecords();
  },
  methods: {
    fetchLedenRecords() {
      const db = firebase.firestore();
      db.collection("usersCollection")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const record = doc.data();
            record.id = doc.id;
            this.ledenRecords.push(record);
          });
        })
        .catch((error) => {
          console.error("Error fetching leden records: ", error);
        });

      db.collection("customersCollection")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.customers.push(doc.data());
          });
        })
        .catch((error) => {
          console.error("Error fetching customers: ", error);
        });
    },
    fetchInschrijvingRecords() {
      const db = firebase.firestore();
      db.collection("inschrijvingCollection")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const inschrijving = doc.data();
            inschrijving.id = doc.id;
            this.inschrijvingRecords.push(inschrijving);
          });
        })
        .catch((error) => {
          console.error("Error fetching inschrijvingen records: ", error);
        });
    },
    paymentActive(email) {
      return this.customers.some((customer) => customer.email === email);
    },
    disableUser(email) {
      fetch(`/api/disableUser?email=${email}`)
        .then((response) => response.json())
        .then((data) => {
          console.log("User disabled", data);
        });
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    sortBy(tab, key) {
      if (this.sortKey[tab] === key) {
        this.sortOrder[tab] = this.sortOrder[tab] === "asc" ? "desc" : "asc";
      } else {
        this.sortKey[tab] = key;
        this.sortOrder[tab] = "asc";
      }
    },
  },
};
</script>

<style scoped>
.tab-button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: lightgray;
  border: none;
  border-radius: 5px;
  margin-right: 5px;
}

.tab-button.active {
  background-color: gray;
  color: white;
}

.int-table__cell--sort {
  cursor: pointer;
}

.int-table__cell--sort:hover {
  background-color: #f0f0f0;
}

.filter-dropdown {
  margin-left: 10px;
  padding: 5px;
}
</style>
