import firebase from "firebase";
import "firebase/firestore";

// firebase init goes here
const config = {
  apiKey: "AIzaSyD-qHBvOD12h5wLTf-u4w9pSG9DlDcnn7s",
  authDomain: "ocgenootschap.firebaseapp.com",
  projectId: "ocgenootschap",
  storageBucket: "ocgenootschap.appspot.com",
  messagingSenderId: "862363321134",
  appId: "1:862363321134:web:0e5ac71d0efac659f2b0bf",
  measurementId: "G-HG8L9L48VF"
};
firebase.initializeApp(config);
firebase.analytics();


// firebase utils
const db = firebase.firestore();
const auth = firebase.auth();
const currentUser = auth.currentUser;
const usersCollection = db.collection("usersCollection");
const customersCollection = db.collection("customersCollection");
const inschrijvingCollection = db.collection("inschrijvingCollection");
// firebase collections
// const customersCollection = db.collection("customers");
// const plansCollection = db.collection("plans");


export {
  config,
  db,
  auth,
  currentUser,
  usersCollection,
  customersCollection,
  inschrijvingCollection
};
