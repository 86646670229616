import Vue from "vue";
import axios from "axios";
import { firestorePlugin } from "vuefire";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.use(firestorePlugin);
Vue.config.productionTip = false;

let app, content;

axios
  .get("/api/getcontent")
  .then((res) => {
    content = res.data;
    initializeVue();
  })
  .catch((error) => {
    console.log(error);
    // initializeVue()
  });

function initializeVue() {
  store.state.siteContent = content;

    window.app = new Vue({
      el: "#app",
      router,
      store,
      render: (h) => h(App),
    });
  
}
