<template>
  <div class="padding-y-xl">
    <div class="container max-width-lg">
      <div class="text-component text-center"><h3>Onze sponsoren</h3></div>
      <div class="margin-top-lg">
        <ul class="customer-logos flex flex-wrap gap-lg flex-center">
          <li v-for="sponsor in sponsoren" :key="sponsor.name">
              <!-- <pre>
              {{ sponsor }}
              </pre> -->
            <img
              :src="`//images.weserv.nl/?url=${sponsor.logo.permalink}&w=180&h=60&fit=contain&a=attention`"
            />
          </li>

        </ul>
      </div>
    </div>
  </div>
</template>


<script>

export default {
    props: {
        sponsoren: Array
    }
}
</script>
